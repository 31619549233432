.footer {
    background-color: #023056 !important;
    width: '100%';
    margin-top: 5vh;
    padding: 3vh;
    color: white !important;
}

.footer__text {
    font-weight: bold !important;
    color: white !important;
}

.footer__text h6 {
    font-size: 32px !important;
}

.footer__rights {
    text-align: center !important;
    font-size: 12px !important;
}