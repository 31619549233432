.appweb {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    width: 80%;
}
.appweb__paper {
    padding: 2vh;
    justify-content: space-between;
}
.appweb h2 {
    font-family: 'Quicksand', sans-serif !important;
    font-weight: bold !important;
    font-size: 50px !important;
}

.appweb__problem h2 {
    font-family: 'Quicksand', sans-serif !important;
    font-weight: bold !important;
    font-size: 50px !important;
}
.appweb__problem p {
    font-family: 'Quicksand', sans-serif !important;
    font-size: 20px !important;
    text-align: justify;
}
.appweb__problem img {
    width: 45%;
    height: 45%;
    border: 1px solid gray;
    border-radius: 1%;
    align-self: center;
}