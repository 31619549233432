.homepage {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.homepage__app {
    width: 70ch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 300px;
}

.homepage__mockup {
    width: 350px;
}

.homepage__title {
    font-size: 50px !important;
    font-weight: bold !important;
    text-align: center;
}

.homepage__subtitle {
    font-size: 28px !important;
    font-weight: bold !important;
    text-align: center;
}

.homepage__text {
    font-size: 20px !important;
    text-align: center;
}

.homepage__grid {
    padding: 1vh;
    display: flex !important;
    align-items: center;
    justify-content: space-evenly !important;
    text-align: center !important;
}

.homepage__griditem {
    display: flex !important;
    border-radius: 1vh;
    padding: 1vh !important;
    border: 1px #cccbcb70 solid;
    background-color: white;
    text-align: center;
    height: 250px !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center;
    max-width: 34vh !important;
}

.homepage__img {
    width: 130px;
}