.team {
    display: flex !important;
    align-items: center !important;
}

.team__company {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    height: 150px;
}

.team__company--logo {
    height: 130px;
}

.team__title--logo {
    font-family: "Diphylleia", serif;
}

.team__element {
    display: flex !important;
    align-items: start !important;
    border: 1px #e0e0e0 solid;
    padding: 10px 20px 10px 20px;
}

.team__text {
    color: #0061a2 !important;
    font-size: 17px;
    align-content: center;
}

.team__text--bold {
    font-size: 20px;
}

.team__title {
    text-align: center;
    font-size: 20px;
}

.css-1fy9j49-MuiStack-root>:not(style)~:not(style) {
    margin-top: 0!important;
}